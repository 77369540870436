var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"c-page-header"},[_c('div',{staticClass:"relative pt-14 >=1024:pt-lg >=1024:grid >=1024:grid-cols-12 gap-x-layout-gutter",staticStyle:{"grid-template-rows":"auto 1fr"}},[(_vm.showFlair)?_c('LensFlare',{class:[
				'c-page-header__lens-flare',
				'c-page-header__lens-flare--desktop',
				'absolute top-0 right-0',
				'hidden >=1024:block',
			],attrs:{"id":"c-page-header__flare-desktop","angle":45,"min-blur-amount":14,"max-blur-amount":17,"flares":[
				{
					size: 600,
					targetSize: 450,
					opacity: 0.5,
					offset: 500,
					targetOffset: 900,
					theme: 'secondary',
					classes: [
						'c-page-header__lens-flare',
						'c-page-header__lens-flare-first',
					],
				},
				{
					size: 1000,
					offset: 0,
					targetOffset: 200,
					theme: 'primary',
					opacity: 1,
					classes: [
						'c-page-header__lens-flare',
						'c-page-header__lens-flare-second',
					],
				},
				{
					size: 600,
					offset: -450,
					theme: 'secondary',
					opacity: 0.8,
					classes: [
						'c-page-header__lens-flare',
						'c-page-header__lens-flare-third',
					],
				},
			]}}):_vm._e(),_vm._v(" "),(_vm.showFlair)?_c('LensFlare',{class:[
				'c-page-header__lens-flare',
				'c-page-header__lens-flare--mobile',
				'absolute top-0 right-0',
				'>=1024:hidden',
			],attrs:{"id":"c-page-header__flare-mobile","angle":45,"min-blur-amount":20,"max-blur-amount":20,"flares":[
				{
					size: 700,
					targetSize: 450,
					opacity: 0.5,
					offset: 500,
					theme: 'secondary',
					classes: [
						'c-page-header__lens-flare',
						'c-page-header__lens-flare-first',
					],
				},
				{
					size: 1000,
					offset: 0,
					theme: 'primary',
					opacity: 1,
					classes: [
						'c-page-header__lens-flare',
						'c-page-header__lens-flare-second',
					],
				},
				{
					size: 650,
					offset: -400,
					theme: 'secondary',
					opacity: 0.8,
					classes: [
						'c-page-header__lens-flare',
						'c-page-header__lens-flare-third',
					],
				},
			]}}):_vm._e(),_vm._v(" "),(_vm.breadcrumb && _vm.breadcrumb.length)?_c('PageBreadcrumb',{class:{
				'mb-xl': _vm.headerImage,
				// Layout depending on image and image placement
				'>=1024:col-start-1 >=1024:col-end-6 >=1024:mb-0':
					!_vm.useWideImage && _vm.headerImage,
				'>=1024:col-start-1 >=1024:col-end-13':
					_vm.useWideImage || !_vm.headerImage,
			},attrs:{"items":_vm.breadcrumb}}):_vm._e(),_vm._v(" "),(_vm.headerImage)?_c('figure',{class:{
				// Layout depending on image and image placement
				'>=1024:col-start-6 >=1024:col-end-13 >=1024:row-span-2 >=1024:pl-3xl':
					!_vm.useWideImage,
				'>=1024:col-start-1 >=1024:col-end-11': _vm.useWideImage,
			}},[_c('UmbracoImageExt',{staticClass:"c-page-header__image w-full rounded-r-full overflow-hidden",class:{
					'>=1024:rounded-l-full': !_vm.useWideImage,
				},attrs:{"image-class-names":{
					'rounded-r-full': true,
					'>=1024:rounded-l-full': !_vm.useWideImage,
				},"widths":[
					347,
					480,
					688,
					740,
					928,
					1088,
					688 * 2,
					740 * 2,
					928 * 2,
					1088 * 2,
				],"sizes":_vm.imageSizes({
						default: '92vw',
						768: _vm.useWideImage ? '76vw' : '48vw',
					}),"alt":_vm.headerImage.altText,"source-url":_vm.headerImage.cropUrl,"source-width":_vm.headerImage.width,"source-height":_vm.headerImage.height,"aspect-ratio":_vm.useWideImage ? 1088 / 422 : 712 / 422,"lazyload":false}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-3xl >=1024:mt-4xl",class:{
				// Layout depending on image and image placement
				'>=1024:col-start-1 >=1024:col-end-6 ':
					!_vm.useWideImage && _vm.headerImage,
				'>=1024:col-start-1 >=1024:col-end-10':
					_vm.useWideImage || !_vm.headerImage,
			}},[(_vm.computedNewsDate)?_c('BaseH4',{staticClass:"mb-2xs",attrs:{"tag":"div"},domProps:{"textContent":_vm._s(_vm.computedNewsDate)}}):_vm._e(),_vm._v(" "),(_vm.headerTitle || _vm.title)?_c('BaseH1',{domProps:{"innerHTML":_vm._s(_vm.headerTitle || _vm.title)}}):_vm._e(),_vm._v(" "),(_vm.headerDescription || _vm.manchet)?_c('p',{staticClass:"text-teaser font-darker-grotesque whitespace-pre-wrap mt-xl",domProps:{"innerHTML":_vm._s(_vm.headerDescription || _vm.manchet)}}):_vm._e()],1)],1),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }