var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"d-overview-page-custom"},[_c('PageHeader',_vm._b({},'PageHeader',{
			..._vm.$attrs,
			..._vm.$props,
		},false)),_vm._v(" "),(_vm.items && _vm.items.length)?_c('div',{class:[
			'grid grid-cols-1 >=605:grid-cols-2 >=1024:grid-cols-3',
			'gap-layout-gutter mt-4xl',
		]},_vm._l((_vm.items),function(item,index){return _c('ContentCard',_vm._b({key:`item-${index}`,staticClass:"h-full"},'ContentCard',{
				...item,
				link: { url: item.url },
				theme: null,
				image: _vm.isVisualOverviewPage ? item.image : null,
				useFallbackImage: _vm.isVisualOverviewPage,
			},false))}),1):_vm._e(),_vm._v(" "),(_vm.contentElements && _vm.contentElements.length)?_c('BlockList',{staticClass:"mt-6xl",attrs:{"block-list":_vm.contentElements}}):_vm._e(),_vm._v(" "),(!_vm.hideFeedback)?_c('FeedbackModule',{staticClass:"mt-7xl",attrs:{"feedback":_vm.feedback}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }