<template>
	<article class="d-overview-page-custom">
		<PageHeader
			v-bind="{
				...$attrs,
				...$props,
			}"
		/>

		<div
			v-if="items && items.length"
			:class="[
				'grid grid-cols-1 >=605:grid-cols-2 >=1024:grid-cols-3',
				'gap-layout-gutter mt-4xl',
			]"
		>
			<ContentCard
				v-for="(item, index) in items"
				:key="`item-${index}`"
				class="h-full"
				v-bind="{
					...item,
					link: { url: item.url },
					theme: null,
					image: isVisualOverviewPage ? item.image : null,
					useFallbackImage: isVisualOverviewPage,
				}"
			/>
		</div>

		<BlockList
			v-if="contentElements && contentElements.length"
			:block-list="contentElements"
			class="mt-6xl"
		/>

		<FeedbackModule
			v-if="!hideFeedback"
			:feedback="feedback"
			class="mt-7xl"
		/>
	</article>
</template>

<script>
import PageHeader from '~/components/main/PageHeader';
import BlockList from '~/components/main/BlockList';
import ContentCard from '~/components/shared/ContentCard';
import FeedbackModule from '~/components/shared/FeedbackModule';

export default {
	name: 'MainOverviewPageCustom',

	components: {
		PageHeader,
		BlockList,
		ContentCard,
		FeedbackModule,
	},

	inheritAttrs: false,

	props: {
		hideFeedback: {
			type: Boolean,
			default: false,
		},
		feedback: Object,
		title: String,
		items: Array,
		contentElements: Array,
		isVisualOverviewPage: Boolean,
	},
};
</script>
