<template>
	<header class="c-page-header">
		<div
			class="
				relative
				pt-14
				>=1024:pt-lg >=1024:grid >=1024:grid-cols-12
				gap-x-layout-gutter
			"
			style="grid-template-rows: auto 1fr"
		>
			<!-- Lens Flare -->
			<LensFlare
				v-if="showFlair"
				id="c-page-header__flare-desktop"
				:class="[
					'c-page-header__lens-flare',
					'c-page-header__lens-flare--desktop',
					'absolute top-0 right-0',
					'hidden >=1024:block',
				]"
				:angle="45"
				:min-blur-amount="14"
				:max-blur-amount="17"
				:flares="[
					{
						size: 600,
						targetSize: 450,
						opacity: 0.5,
						offset: 500,
						targetOffset: 900,
						theme: 'secondary',
						classes: [
							'c-page-header__lens-flare',
							'c-page-header__lens-flare-first',
						],
					},
					{
						size: 1000,
						offset: 0,
						targetOffset: 200,
						theme: 'primary',
						opacity: 1,
						classes: [
							'c-page-header__lens-flare',
							'c-page-header__lens-flare-second',
						],
					},
					{
						size: 600,
						offset: -450,
						theme: 'secondary',
						opacity: 0.8,
						classes: [
							'c-page-header__lens-flare',
							'c-page-header__lens-flare-third',
						],
					},
				]"
			/>

			<!-- Lens Flare Mobile -->
			<LensFlare
				v-if="showFlair"
				id="c-page-header__flare-mobile"
				:class="[
					'c-page-header__lens-flare',
					'c-page-header__lens-flare--mobile',
					'absolute top-0 right-0',
					'>=1024:hidden',
				]"
				:angle="45"
				:min-blur-amount="20"
				:max-blur-amount="20"
				:flares="[
					{
						size: 700,
						targetSize: 450,
						opacity: 0.5,
						offset: 500,
						theme: 'secondary',
						classes: [
							'c-page-header__lens-flare',
							'c-page-header__lens-flare-first',
						],
					},
					{
						size: 1000,
						offset: 0,
						theme: 'primary',
						opacity: 1,
						classes: [
							'c-page-header__lens-flare',
							'c-page-header__lens-flare-second',
						],
					},
					{
						size: 650,
						offset: -400,
						theme: 'secondary',
						opacity: 0.8,
						classes: [
							'c-page-header__lens-flare',
							'c-page-header__lens-flare-third',
						],
					},
				]"
			/>

			<!-- Breadcrumb -->
			<PageBreadcrumb
				v-if="breadcrumb && breadcrumb.length"
				:class="{
					'mb-xl': headerImage,
					// Layout depending on image and image placement
					'>=1024:col-start-1 >=1024:col-end-6 >=1024:mb-0':
						!useWideImage && headerImage,
					'>=1024:col-start-1 >=1024:col-end-13':
						useWideImage || !headerImage,
				}"
				:items="breadcrumb"
			/>

			<!-- Image -->
			<figure
				v-if="headerImage"
				:class="{
					// Layout depending on image and image placement
					'>=1024:col-start-6 >=1024:col-end-13 >=1024:row-span-2 >=1024:pl-3xl':
						!useWideImage,
					'>=1024:col-start-1 >=1024:col-end-11': useWideImage,
				}"
			>
				<UmbracoImageExt
					class="
						c-page-header__image
						w-full
						rounded-r-full
						overflow-hidden
					"
					:class="{
						'>=1024:rounded-l-full': !useWideImage,
					}"
					:image-class-names="{
						'rounded-r-full': true,
						'>=1024:rounded-l-full': !useWideImage,
					}"
					:widths="[
						347,
						480,
						688,
						740,
						928,
						1088,
						688 * 2,
						740 * 2,
						928 * 2,
						1088 * 2,
					]"
					:sizes="
						imageSizes({
							default: '92vw',
							768: useWideImage ? '76vw' : '48vw',
						})
					"
					:alt="headerImage.altText"
					:source-url="headerImage.cropUrl"
					:source-width="headerImage.width"
					:source-height="headerImage.height"
					:aspect-ratio="useWideImage ? 1088 / 422 : 712 / 422"
					:lazyload="false"
				/>
			</figure>

			<!-- Text -->
			<div
				class="mt-3xl >=1024:mt-4xl"
				:class="{
					// Layout depending on image and image placement
					'>=1024:col-start-1 >=1024:col-end-6 ':
						!useWideImage && headerImage,
					'>=1024:col-start-1 >=1024:col-end-10':
						useWideImage || !headerImage,
				}"
			>
				<BaseH4
					v-if="computedNewsDate"
					tag="div"
					class="mb-2xs"
					v-text="computedNewsDate"
				/>
				<BaseH1
					v-if="headerTitle || title"
					v-html="headerTitle || title"
				/>
				<p
					v-if="headerDescription || manchet"
					class="
						text-teaser
						font-darker-grotesque
						whitespace-pre-wrap
						mt-xl
					"
					v-html="headerDescription || manchet"
				></p>
			</div>
		</div>
		<slot></slot>
	</header>
</template>

<script>
import { imageSizes } from '@limbo-works/vue-umbraco-image';

import PageBreadcrumb from '~/components/shared/PageBreadcrumb';
import LensFlare from '~/components/shared/LensFlare';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';

export default {
	name: 'PageHeader',
	components: { PageBreadcrumb, LensFlare, UmbracoImageExt },
	inheritAttrs: false,

	props: {
		breadcrumb: Array,
		headerTitle: String,
		title: String, // Fallback for headerTitle
		headerDescription: String,
		manchet: String, // Fallback for headerDescription
		headerImage: Object,
		newsDate: String,

		// If true, the image will be displayed in wide format
		// If false, the image will be displayed to the right
		useWideImage: Boolean,

		showFlair: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		computedNewsDate() {
			if (this.newsDate) {
				const date = new Date(this.newsDate);
				const day = `0${date.getDate()}`.slice(-2);
				const month = `0${date.getMonth() + 1}`.slice(-2);
				const year = date.getFullYear();
				return `${day}.${month}.${year}`;
			}

			return false;
		},
	},

	methods: {
		imageSizes,
	},
};
</script>

<style lang="postcss">
.c-page-header__image {
	min-height: 180px;
	height: 29.3vw;
	max-height: 422px;

	& img {
		@apply object-cover;
	}
}

.c-page-header__lens-flare--desktop {
	width: 60%;
	transform: translate(0, -35%);
}

.c-page-header__lens-flare--mobile {
	width: 60%;
	transform: translate(0, -60%);
}

/**
 * Transform on inner container, to avoid
 * overflow issues.
 */
.c-page-header__lens-flare--desktop .c-lens-flare__inner {
	transform: translateX(20vw);
}

.c-page-header__lens-flare--mobile .c-lens-flare__inner {
	transform: translateX(256px);
}

.c-page-header__lens-flare-first,
.c-page-header__lens-flare-second,
.c-page-header__lens-flare-third {
	transform: translate(0px, 0px);
}
</style>
